<template>
	<client-only>
		<section class="base-form-wrapper">
		<base-container>
			<div class="inner-wrapper">
				<figure class="img-wrapper">
					<cms-image
						class="img"
						:src="value.section_image"
						position="top"
						:base="{width: 500, height: 400}"
						:md="{width: 800, height: 400, position: 'center'}"
                        :lg="{width: 585, height: 500}"
                        :xl="{width: 585, height: 578}"
						layout
					/>
				</figure>
				<div class="section-content">
					<div class="wrapper">
						<base-heading tag="h2" :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`" class="title"/>
						<div class="benefits">
							<base-benefit  v-for="(benefit, index) in value.tiles" :key="index" :benefit="benefit"/>
						</div>
					</div>
				</div>
			</div>
			<aside class="aside">
				<base-svg :name="value.section_aside_icon" class="aside-icon"/>
				<base-font class="aside-title" tag="p" color="white" uppercase size="md">{{ value.section_aside_title }}</base-font>
			</aside>
		</base-container>
	</section>
	</client-only>
</template>
<script>
import ClientOnly from 'vue-client-only'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import BaseBenefit from '../../components/atoms/BaseBenefit/BaseBenefit.vue'
export default {
	components: { ClientOnly, BaseHeading, BaseBenefit },
	props: {
		prefix: String,
		value: Object
	}
}
</script>
<style lang="scss" scoped>

body.safari{
	.base-form-wrapper{
		.benefits{
			display: block;

			@include media-breakpoint-up(xl) {
				display: flex;
			}
		}
	}

}

.base-form-wrapper {
	overflow: hidden;
	padding-bottom: 4rem;
	background-color: $gray-900;
	position: relative;

	@include media-breakpoint-up(lg){
		padding-top: 4rem;
	}

    @include media-breakpoint-up(xl) {
        padding: 8rem 0;
		background-color: $white;
    }
	.inner-wrapper {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 420px 510px;
			margin-bottom: -10px;
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: 425px 640px;
			gap: 30px;
		}
		@include media-breakpoint-up(xxl) {
			grid-template-columns: 425px auto;
			padding-bottom: 6rem;
		}
	}
}
.img-wrapper {
	width: 100vw;
	position: relative;
	left: 50%;
	transform: translateX(-100%);
	margin: 0 0 4rem 50vw;
	z-index: 1;
	@include media-breakpoint-up(lg) {
		width: 515px;
		position: relative;
		left: 0;
		margin: 0;
		transform: translateX(-26%);
		margin-bottom: 0;
	}
	@include media-breakpoint-up(xl) {
		width: auto;
		transform: none;
		margin: 0;
		left: -280px;
	}

	@include media-breakpoint-up(xxl) {
		transform: scale(1.2) translateY(20px);
	}

	@media (min-width: 2500px) {
		width: 400px;
		left: -585px;
	}
	.img {
		box-shadow: 6px 6px 38px rgba(0, 0, 0, 0.27);
		@include media-breakpoint-up(xl) {
			display: inline-block;
		}
		::v-deep img {
			width: 100%;
			height: 180px;
			object-fit: cover;
			border-radius: .2rem 0px 0px .2rem;
			display: block;

			@include media-breakpoint-up(md) {
				height: 300px;
			}
			@include media-breakpoint-up(lg) {
				width: auto;
				height: auto;
			}

			@media (min-width: 2500px) {
				width: 1050px;
				height: 1150px;
			}
		}
        ::v-deep picture {
            display: block;
        }
	}
}

.section-content {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%) translateX(-40%);
		width: 110vw;
		height: 100%;
		background-color: $gray-900;
		z-index: 0;
		display: none;

		@include media-breakpoint-up(lg) {
			height: 94%;
		}
		@include media-breakpoint-up(xl) {
			display: block;
		}
	}

	::v-deep .heading-wrapper {
		margin-bottom: 3rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 2rem;
		}
		@include media-breakpoint-up(xxl) {
			margin-bottom: 6rem;
		}
	}

	::v-deep .wrapper {
		position: relative;
		z-index: 1;
		padding: 4rem 0 1.5rem 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.benefits {
	display: grid;
	gap: 2rem;

	@include media-breakpoint-up(xl) {
		display: flex;
	}

	@include media-breakpoint-up(xxl) {
		gap: 3rem;
	}
}

.aside {
	display: none;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	background-color: $secondary;
	padding: 1.3rem 0;
	position: absolute;
	right: 0;

	@include media-breakpoint-up(xl) {
		bottom: 2.9rem;
		width: 100%;
		z-index: 1;
		display: flex;
	}
	@include media-breakpoint-up(xxl) {
		bottom: 8.9rem;
		width: 100%;
		z-index: 0;
	}

	&-icon {
		max-width: 3.2rem;
		min-width: 2rem;
	}

	::v-deep .aside-title {
		margin-bottom: 0;
	}
}
</style>
